//------------------------------------------------------------------------
// Append icons to links and prevent them from wrapping
//
// Note: “height” and “width” attributes will be used as the default size
//        but can be overridden using “data-icon-width”/“data-icon-height”
//------------------------------------------------------------------------
import Unorphanize from "@threespot/unorphanize";

// Use simple rounding function since we only need one decimal place.
// This apprach has issues when rounding to more decimal places:
// e.g. Math.round(1.005 * 100) / 100; // 1 instead of 1.01
// http://www.jacklmoore.com/notes/rounding-in-javascript/
const roundSingleDecimal = function(number) {
  return Math.round(number * 10) / 10;
};

// When more decimal places are desired, see this MDN page for a better function:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round#A_better_solution
//
// function round(number, precision) {
//   var shift = function (number, exponent) {
//     var numArray = ("" + number).split("e");
//     return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + precision) : precision));
//   };
//   return shift(Math.round(shift(number, +exponent)), -exponent);
// }

const icons = {
  chevRight: {
    class: "icon icon-chevRight",
    viewBox: "0 0 10 17",
    width: 10,
    path: `<path d="M1 0l8 8-8 9"/>`,
    customAttrs: 'stroke="#fff" fill="none" stroke-linecap="square"' // optional additional attrs for SVG
  }
  // download: {
  //   class: "icon icon-download",
  //   viewBox: "0 0 576 768",
  //   width: 12,
  //   path: `<path d="M256 0h64v530l169-169 46 46-224 224-23 22-23-22L41 407l46-46 169 169V0zM0 704h576v64H0v-64z"/>`
  // },
  // external: {
  //   class: "icon icon-external",
  //   viewBox: "0 0 576 512",
  //   width: 16,
  //   path: `<path d="M448 279v185c0 27-21 48-48 48H48c-27 0-48-21-48-48V112c0-27 21-48 48-48h248a24 24 0 0 1 17 7l16 16c15 15 4 41-17 41H64v320h320V295c0-6 3-12 7-17l16-16c15-15 41-4 41 17zM576 37c0-20-17-37-37-37H380c-15 0-28 13-28 28v18c0 16 13 28 29 28l67-2-249 247c-9 9-9 25 0 34l24 24c9 9 25 9 34 0l247-249-2 67c0 16 12 29 28 29h18c15 0 28-13 28-28V37z"/>`
  // }
};

const buildSVG = function(icon) {
  return `
    <svg class="${icon.class}" viewBox="${icon.viewBox}" width="${icon.width}" height="${icon.height}" preserveAspectRatio="xMidYMid meet" aria-hidden="true" focusable="false" ${icon.customAttrs}>
      ${icon.path}
    </svg>`;
};

const nodes = document.querySelectorAll("[data-icon]");

nodes.forEach(function(el) {
  let iconName = el.getAttribute("data-icon");

  // Check if icon exists
  if (!(iconName in icons)) {
    console.warn(`Icon “${iconName}” was not found in link-icons.js`, el);
    return false;
  }

  // If link is external, use external icon (excluding certain CTA links/buttons below)
  // NOTE: This is project-specific, edit as needed.
  // External link test from https://gist.github.com/jlong/2428561
  // if (!el.classList.contains("Exmaple")) {
  //   var a = document.createElement("a");
  //   a.href = el.href;
  //   if (a.hostname !== window.location.hostname) {
  //     iconName = "external";
  //   }
  // }

  // Create new object for this icon so we can change
  // the dimensions without affecting the defaults.
  const icon = {};

  // Copy values from original icons object
  Object.assign(icon, icons[iconName]);

  // Check for custom size attributes
  let iconHeight = el.getAttribute("data-icon-height");
  let iconWidth = el.getAttribute("data-icon-width");

  // Validate height and width values if present
  // Note: Use unary plus (+) operator to convert strings to numbers
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Arithmetic_Operators#Unary_plus_()
  if (iconHeight) {
    if (isNaN(+iconHeight)) {
      console.warn(`Can’t parse data-icon-height value of “${iconHeight}” on ${el}`);
      return false;
    } else {
      icon.height = +iconHeight;
    }
  }

  if (iconWidth) {
    if (isNaN(+iconWidth)) {
      console.warn(`Can parse data-icon-width value of “${iconWidth}” on ${el}`);
      return false;
    } else {
      icon.width = +iconWidth;
    }
  }

  // Make sure either the height or width has been defined
  if (!icon.height && !icon.width) {
    console.warn(`No height or width defined for icon “${iconName}”`, icon);
    return false;
  }

  // Calculate height or width if only one dimension was provided
  // Note: We can’t rely on CSS to resize SVGs in IE11-
  //       because IE doesn’t respect the viewBox ratio.
  let viewBoxArr = icon.viewBox.split(" ");

  // Validate viewBox value
  if (viewBoxArr.length !== 4) {
    console.warn(`Icon “${iconName}” has a malformed viewBox attribute: “${icon.viewBox}”`);
    return false;
  }

  // Calculate aspect ratio
  let aspectRatio = +viewBoxArr[2] / +viewBoxArr[3];

  // Calculate height if width was provided
  if (!icon.height && icon.width) {
    icon.height = roundSingleDecimal(icon.width / aspectRatio);
  }

  // Calculate width if height was provided
  if (!icon.width && icon.height) {
    icon.width = roundSingleDecimal(icon.height * aspectRatio);
  }

  // Insert the icon using Unorphanize to prevent wrapping
  new Unorphanize(el, {
    inlineStyles: false,
    className: "u-nowrap",
    append: buildSVG(icon)
  });
});
