//------------------------------------------------------------------------
// Carousel built with Flickity https://flickity.metafizzy.co
//------------------------------------------------------------------------
import Flickity from "flickity";

"use strict";

export default class ParkCarousel {
  constructor(el) {
    let self = this;
    this.el = el;
    this.images = document.querySelectorAll(".Park-background-image");
    this.activeSlideIndex = 0;

    this.flkty = new Flickity(this.el, {
      adaptiveHeight: true,
      arrowShape: "M11.3 50.3L57 8.7A4.5 4.5 0 1 0 51 1.9L1.5 47c-2 1.8-2 5 0 6.7l50.5 46a4.5 4.5 0 0 0 6-6.7L11.4 50.3z",
      dragThreshold: 15,// improve UX on mobile devices when scrolling
      wrapAround: true,
      on: {
        ready: function() {
          // Update “role” attribute to account for markup changes after Flickity inits
          // (Flickity doesn’t work with ul/li so we have to use divs with role attrs)
          this.element.removeAttribute("role");
          this.element.classList.add("is-ready");
          self.el.querySelector(".flickity-slider").setAttribute("role", "list");
          self.el.querySelector(".flickity-page-dots").classList.add("l-padding");
          // Show first image
          if (self.images.length) {
            self.images[0].classList.add("is-active");
          }
        }
      }
    });

    // Listen for when slide changes and update the background image
    this.flkty.on("change", (index) => {
      this.showImage(index);
    });
  }

  showImage(index) {
    if (this.images[index]) {
      this.images[this.activeSlideIndex].classList.remove("is-active");
      this.images[index].classList.add("is-active");
      this.activeSlideIndex = index;
    }
  }
}

const carousel = document.querySelector(".Park-carousel");

if (carousel) {
  new ParkCarousel(carousel);
}
