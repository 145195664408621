//------------------------------------------------------------------------
// Mobile nav menu
//------------------------------------------------------------------------
"use strict";
import ExpandToggle from "@threespot/expand-toggle";

class NavMenu {
  constructor() {
    // NOTE: Breakpoint must match $layout-nav-bp in _layout-vars.scss
    this.mediaQueryList = window.matchMedia("(min-width: 960px)");
    this.navToggle = document.querySelector(".Header-navToggle");

    // Close nav if click off of it
    window.addEventListener("click", evt => {
      if (
        this.isMenuExpanded() &&
        !evt.target.closest("#nav") &&
        !evt.target.closest(".Header")
      ) {
        this.navExpandToggle.collapse();
      }
    });

    // Close with escape key
    window.addEventListener("keydown", evt => {
      if (evt.which === 27) {
        this.navExpandToggle.collapse();
      }
    });

    // Listen for breakpoint change
    this.mediaQueryList.addListener(evt => {
      if (evt.matches) {
        this.destroy();
      } else {
        this.init();
      }
    });

    // Init on load
    if (!this.mediaQueryList.matches) {
      this.init();
    }
  }

  isMenuExpanded() {
    return this.navToggle.getAttribute("aria-expanded") == "true";
  }

  init() {
    this.navExpandToggle = new ExpandToggle(this.navToggle);
  }

  destroy() {
    this.navExpandToggle.destroy();
  }
}

// Init
new NavMenu();
