//------------------------------------------------------------------------
// Carousel built with Flickity https://flickity.metafizzy.co
//------------------------------------------------------------------------
import Flickity from "flickity";

"use strict";

export default class HomepageCarousel {
  constructor(el) {
    let self = this;
    this.el = el;
    this.video = document.querySelector(".HomeHero-video");
    this.clipDuration = 6;// seconds, should match homepage video

    this.flkty = new Flickity(this.el, {
      autoPlay: this.clipDuration * 1000,
      dragThreshold: 15,// improve UX on mobile devices when scrolling
      prevNextButtons: false,
      wrapAround: true,
      on: {
        ready: function() {
          // Update “role” attribute to account for markup changes after Flickity inits
          // (Flickity doesn’t work with ul/li so we have to use divs with role attrs)
          this.element.removeAttribute("role");
          this.element.classList.add("is-ready");
          self.el.querySelector(".flickity-slider").setAttribute("role", "list");
        }
      }
    });

    // Listen for slide change and update the video to match
    if (this.video) {
      this.flkty.on("change", (index) => {
        this.video.currentTime = this.clipDuration * index;
      });
    }
  }
}

const carousel = document.querySelector(".HomeHero-callout");

if (carousel) {
  new HomepageCarousel(carousel);
}
